import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../../components/SEO/SEO';
import { Link } from 'react-router-dom';

const BlogContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.text};
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Category = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.text};
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.textSecondary};
`;

const MetaInfo = styled.div`
  display: flex;
  gap: 1rem;
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 2rem;
  font-size: 0.9rem;
`;

const Content = styled.div`
  line-height: 1.8;
  
  h2 {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.text};
    margin: 2.5rem 0 1rem;
  }
  
  p {
    margin-bottom: 1.5rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      margin-bottom: 0.75rem;
      
      &:before {
        content: "•";
        color: ${props => props.theme.colors.primary};
        position: absolute;
        left: -1.5rem;
      }
    }
  }
`;

const ModernWebDesignTrends = () => {
  return (
    <>
      <SEO 
        title="Modern Web Design Trends for 2024 | NeonPixel"
        description="Creating engaging digital experiences. Discover the latest web design trends shaping the digital landscape in 2024."
        keywords="web design trends, 2024 design trends, minimalism, dark mode, AI-driven personalization, accessibility, modern web design"
        url="/blog/modern-web-design-trends-2024"
        type="article"
      />
      
      <BlogContainer>
        <BackLink to="/blog">← Back to Blog</BackLink>
        
        <Category>WEB DESIGN</Category>
        <Title>Modern Web Design Trends for 2024</Title>
        <Subtitle>Creating Engaging Digital Experiences</Subtitle>
        
        <MetaInfo>
          <span>February 15, 2024</span>
          <span>•</span>
          <span>NeonPixel Designs</span>
          <span>•</span>
          <span>5 min read</span>
        </MetaInfo>

        <Content>
          <p>The landscape of web design is constantly evolving, and 2024 brings exciting new trends that are reshaping how we create digital experiences. Let's explore the key design elements that are defining modern websites.</p>

          <h2>Minimalism with Personality</h2>
          <p>Whilst minimalism remains popular, designers are adding unique touches to make websites more memorable. Think subtle animations, custom illustrations, and thoughtful micro-interactions that guide users through their journey.</p>

          <h2>Immersive Animations</h2>
          <p>Motion design has become more sophisticated, with smooth transitions and scroll-triggered animations creating engaging storytelling experiences. However, performance remains crucial – animations should enhance, not hinder, the user experience.</p>

          <h2>Dark Mode & Colour Psychology</h2>
          <p>Dark mode isn't just a preference; it's becoming a standard feature. Designers are exploring creative ways to implement colour schemes that work beautifully in both light and dark modes, whilst using colour psychology to evoke specific emotions.</p>

          <h2>AI-Driven Personalisation</h2>
          <p>Artificial intelligence is enabling unprecedented levels of personalisation. From dynamic content arrangement to adaptive user interfaces, websites are becoming more responsive to individual user preferences and behaviours.</p>

          <h2>Accessibility-First Design</h2>
          <p>Inclusive design is no longer an afterthought. Modern websites are being built with accessibility in mind from the start, ensuring that digital experiences are available to everyone, regardless of their abilities.</p>

          <h2>Key Takeaways:</h2>
          <ul>
            <li>Focus on purposeful animations that enhance user experience</li>
            <li>Implement dark mode with thoughtful colour considerations</li>
            <li>Prioritise accessibility in your design process</li>
            <li>Use AI to create personalised user experiences</li>
            <li>Maintain performance whilst adding interactive elements</li>
          </ul>

          <p>The future of web design is about creating experiences that are not only visually stunning but also inclusive, performant, and personalised. At NeonPixel Designs, we're excited to help organizations implement these trends in ways that serve their unique goals and audience needs.</p>
        </Content>
      </BlogContainer>
    </>
  );
};

export default ModernWebDesignTrends;
