import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../styles/theme';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${theme.colors.background};
`;

const ContentSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: ${theme.colors.text};
`;

const Section = styled(motion.div)`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: ${theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const SubSection = styled.div`
  margin-bottom: 2rem;
`;

const SubTitle = styled.h3`
  color: ${theme.colors.secondary};
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  color: ${theme.colors.textSecondary};
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  color: ${theme.colors.textSecondary};
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const LastUpdated = styled.p`
  color: ${theme.colors.textSecondary};
  font-style: italic;
  margin-top: 3rem;
  text-align: center;
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="NeonPixel Designs privacy policy - Learn how we collect, use, and protect your personal information when you use our website and services."
        keywords="privacy policy, data protection, GDPR compliance, website privacy, personal data, data security, privacy terms"
        url="/privacy-policy"
        type="legal"
      />
      <PageContainer>
        <PageHeader
          title="Privacy Policy"
          subtitle="We value your privacy and are committed to protecting your personal information"
        />
        <ContentSection>
          <Section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Paragraph>
              At NeonPixel, we take your privacy seriously. This Privacy Policy explains how we collect, use, 
              and protect your personal information when you use our website and services.
            </Paragraph>

            <SectionTitle>Information We Collect</SectionTitle>
            <SubSection>
              <SubTitle>Personal Information</SubTitle>
              <List>
                <li>Name and contact details when you reach out to us</li>
                <li>Email address for newsletter subscriptions and communications</li>
                <li>Company information for business inquiries</li>
                <li>Project requirements and preferences</li>
              </List>
            </SubSection>

            <SubSection>
              <SubTitle>Technical Information</SubTitle>
              <List>
                <li>IP address and browser information</li>
                <li>Device information and operating system</li>
                <li>Pages visited and time spent on our website</li>
                <li>Referral sources and navigation patterns</li>
              </List>
            </SubSection>

            <SectionTitle>How We Use Your Information</SectionTitle>
            <SubSection>
              <List>
                <li>To provide and improve our web design and development services</li>
                <li>To communicate with you about your projects and inquiries</li>
                <li>To send relevant updates and newsletters (with your consent)</li>
                <li>To analyze and improve our website performance</li>
                <li>To comply with legal obligations</li>
              </List>
            </SubSection>

            <SectionTitle>Data Protection</SectionTitle>
            <SubSection>
              <Paragraph>
                We implement appropriate technical and organizational measures to protect your personal 
                information against unauthorized access, alteration, disclosure, or destruction.
              </Paragraph>
            </SubSection>

            <SectionTitle>Your Rights</SectionTitle>
            <SubSection>
              <Paragraph>
                You have the right to:
              </Paragraph>
              <List>
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Withdraw consent for data processing</li>
                <li>Object to data processing</li>
                <li>Request data portability</li>
              </List>
            </SubSection>

            <SectionTitle>Cookies</SectionTitle>
            <SubSection>
              <Paragraph>
                We use cookies to enhance your browsing experience and analyze website traffic. 
                You can control cookie preferences through your browser settings.
              </Paragraph>
            </SubSection>

            <SectionTitle>Third-Party Services</SectionTitle>
            <SubSection>
              <Paragraph>
                We may use third-party services for:
              </Paragraph>
              <List>
                <li>Analytics (e.g., Google Analytics)</li>
                <li>Payment processing</li>
                <li>Email communications</li>
                <li>Hosting services</li>
              </List>
              <Paragraph>
                These services have their own privacy policies and data processing practices.
              </Paragraph>
            </SubSection>

            <SectionTitle>Contact Us</SectionTitle>
            <SubSection>
              <Paragraph>
                If you have questions about our Privacy Policy or your personal data, please contact us at:
              </Paragraph>
              <Paragraph>
                Email: hello@neonpixeldesigns.com
              </Paragraph>
            </SubSection>

            <LastUpdated>
              Last updated: January 2024
            </LastUpdated>
          </Section>
        </ContentSection>
      </PageContainer>
    </>
  );
};

export default PrivacyPolicyPage;
