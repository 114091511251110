import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

interface LogoProps {
  width?: string;
  height?: string;
}

const LogoContainer = styled(motion.div)<LogoProps>`
  width: ${props => props.width || '240px'};
  height: ${props => props.height || '40px'};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SVGWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const NeonText = styled(motion.text)`
  font-family: 'Outfit', sans-serif;
  font-weight: bold;
`;

const MainText = styled(NeonText)`
  font-size: 20px;
  fill: ${props => props.theme.colors.primary};
  filter: drop-shadow(0 0 4px ${props => props.theme.colors.primary});
`;

const SubText = styled(NeonText)`
  font-size: 18px;
  fill: ${props => props.theme.colors.secondary};
  filter: drop-shadow(0 0 6px ${props => props.theme.colors.secondary})
         drop-shadow(0 0 12px ${props => props.theme.colors.secondary});
  letter-spacing: 1px;
`;

const NeonRect = styled(motion.rect)`
  fill: none;
  stroke: ${props => props.theme.colors.primary};
  stroke-width: 1.5;
  filter: drop-shadow(0 0 3px ${props => props.theme.colors.primary});
`;

const textVariants = {
  hidden: {
    opacity: 0,
    y: 10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const iconVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

export const Logo: React.FC<LogoProps> = ({ width, height }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <LogoContainer
        width={width}
        height={height}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <SVGWrapper>
          <StyledSVG viewBox="0 0 240 40" xmlns="http://www.w3.org/2000/svg">
            {/* Simple Pixel Icon */}
            <motion.g
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              exit="hidden"
              variants={iconVariants}
            >
              <NeonRect x="10" y="12" width="16" height="16" rx="2" />
              <NeonRect x="14" y="16" width="8" height="8" rx="1" />
            </motion.g>

            {/* Text Group */}
            <motion.g
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
              exit="hidden"
              variants={textVariants}
            >
              <MainText x="40" y="27">Neon</MainText>
              <MainText x="95" y="27">Pixel</MainText>
              <SubText x="155" y="27">DESIGNS</SubText>
            </motion.g>

            {/* Decorative Line */}
            <motion.line
              x1="40"
              y1="32"
              x2="270"
              y2="32"
              stroke={`url(#lineGradient)`}
              strokeWidth="0.5"
              initial={{ pathLength: 0, opacity: 0 }}
              animate={isVisible ? { pathLength: 1, opacity: 0.5 } : { pathLength: 0, opacity: 0 }}
              exit={{ pathLength: 0, opacity: 0 }}
              transition={{ duration: 0.5 }}
            />

            {/* Gradient Definitions */}
            <defs>
              <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#00ffff" />
                <stop offset="100%" stopColor="#ff00ff" />
              </linearGradient>
            </defs>
          </StyledSVG>
        </SVGWrapper>
      </LogoContainer>
    </AnimatePresence>
  );
};

export default Logo;
