import { FaPencilRuler, FaCode } from 'react-icons/fa';

export const services = [
  {
    icon: FaPencilRuler,
    title: 'Web Design',
    description: 'Creating stunning, user-centric designs that captivate your audience and elevate your brand identity. We focus on modern aesthetics, intuitive navigation, and engaging user experiences.',
    features: [
      'Responsive UI/UX Design',
      'Interactive Prototypes',
      'Styled-Components',
      'Framer Motion Animations',
      'Material-UI / Tailwind CSS',
      'Modern Design Systems'
    ],
    path: '/services/web-design'
  },
  {
    icon: FaCode,
    title: 'Web Development',
    description: 'Building robust, scalable websites with modern technologies that deliver exceptional performance. We create fast, secure, and reliable web solutions that grow with your organisation.',
    features: [
      'React & Next.js',
      'TypeScript & JavaScript',
      'Firebase & NoSQL',
      'Node.js & Express',
      'REST APIs & GraphQL',
      'AWS & Cloud Services'
    ],
    path: '/services/web-development'
  }
];
