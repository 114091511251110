export const theme = {
  colors: {
    primary: '#00ffff',    // Neon Cyan
    secondary: '#ff00ff',  // Neon Magenta
    accent: '#ff9100',     // Neon Orange
    background: '#0a0a0a', // Near Black
    backgroundLight: '#1a1a1a',
    text: '#ffffff',       // White
    textSecondary: '#b3b3b3'
  },
  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    wide: '1440px',    // Added back for compatibility
    tablet: '768px',   // Added back for compatibility
    mobile: '576px'    // Added back for compatibility
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '3rem'
  },
  typography: {
    fontFamily: {
      body: 'Inter, sans-serif',
      heading: 'Outfit, sans-serif'
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem'
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    }
  },
  // Added back for compatibility
  fonts: {
    primary: 'Inter, sans-serif',
    heading: 'Outfit, sans-serif'
  },
  transitions: {
    default: '0.3s ease-in-out',
    slow: '0.6s ease-in-out'
  }
} as const;

// Type for the theme
export type Theme = typeof theme;
