import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../../styles/theme';
import Logo from '../Logo/Logo';

interface NavContainerProps {
  $scrolled: boolean;
}

const NavContainer = styled(motion.nav)<NavContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: transparent;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
    height: 50px;
  }
`;

const NavContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  transform: scale(0.9);
`;

export const LandingNavbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <NavContainer
      $scrolled={scrolled}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavContent>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
      </NavContent>
    </NavContainer>
  );
};

export default LandingNavbar;
