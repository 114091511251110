import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/SEO/SEO';
import { 
  FaRocket, FaCheck, FaClock, FaComments, FaCogs, FaShieldAlt, FaChevronDown, FaStar,
  FaPalette, FaChartLine, FaMobileAlt, FaLightbulb, FaRegSmile, FaMagic, FaRegHandshake
} from 'react-icons/fa';
import Logo from '../components/Logo/Logo';

const Section = styled.section`
  width: 100%;
`;

const MaxWidthContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: rgba(15, 0, 36, 0.98);
  position: relative;
`;

const SimpleNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0.5rem 1rem;
  background: transparent;
  display: flex;
  align-items: center;
  height: 60px;
`;

const MainContent = styled.main`
  margin: 0;
  padding: 0;
  flex: 1;
`;

const FloatingIcon = styled(motion.div)`
  position: absolute;
  color: rgba(255, 255, 255, 0.15);
  font-size: 2rem;
  z-index: 1;
  pointer-events: none;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  svg {
    filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.3));
  }
`;

const HeroSection = styled.section`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(15, 0, 36, 0.98) 0%, rgba(25, 0, 45, 0.98) 100%);
  position: relative;
  top: -60px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(135deg, #00ffff 0%, #8A2BE2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const MainHeading = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
`;

const SubHeading = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const CTAButton = styled(motion.button)`
  background: linear-gradient(135deg, #8A2BE2 0%, #5D1C99 100%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(138, 43, 226, 0.3);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(138, 43, 226, 0.4);
  }
`;

const FormSection = styled.section`
  padding: 4rem 0;
  background: rgba(15, 0, 36, 0.98);
  scroll-margin-top: 60px; /* Account for fixed navbar */
  id="consultation-form"
`;

const Form = styled.form`
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 16px;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
  }

  option {
    background: #1a1a1a;
    color: white;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  min-height: 120px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
  }
`;

const PrivacyNote = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1rem;
`;

const BenefitsSection = styled(Section)`
  background: rgba(15, 0, 36, 0.98);
  padding: 6rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 30% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const BenefitCard = styled.div`
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-4px);
    background: rgba(138, 43, 226, 0.08);
    border-color: rgba(138, 43, 226, 0.2);
  }

  svg {
    color: #8A2BE2;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
  }
`;

const TestimonialsSection = styled(Section)`
  background: rgba(20, 0, 36, 0.98);
  padding: 6rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 70% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const TestimonialCard = styled.div`
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;

  .stars {
    color: #FFD700;
    margin-bottom: 1rem;
    display: flex;
    gap: 0.25rem;
  }

  .quote {
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  .author {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
  }

  .position {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
`;

const ProcessSection = styled(Section)`
  background: rgba(15, 0, 36, 0.98);
  padding: 6rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const ProcessCard = styled.div`
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;

  svg {
    color: #8A2BE2;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
  }
`;

const FAQSection = styled(Section)`
  background: rgba(20, 0, 36, 0.98);
  padding: 6rem 0;
`;

const FAQItem = styled.div`
  border-bottom: 1px solid rgba(138, 43, 226, 0.1);
  padding: 1.5rem 0;

  h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      color: #8A2BE2;
      transition: transform 0.3s ease;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
    margin-top: 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const FAQContent = styled(motion.p)`
  overflow: hidden;
`;

const FAQIcon = styled(motion.div)`
  display: flex;
  align-items: center;
`;

interface FAQ {
  question: string;
  answer: string;
  isOpen: boolean;
}

const SimpleFooter = styled.footer`
  background: rgba(15, 0, 36, 0.98);
  padding: 2rem 0;
  text-align: center;
  margin-top: -1px; /* Prevent any gap */
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
  
  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #8A2BE2;
    }
  }
`;

const Copyright = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
`;

const FormHeading = styled.h2`
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
  background: linear-gradient(135deg, #00ffff 0%, #8A2BE2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const FormSubHeading = styled.p`
  text-align: center;
  margin-bottom: 3rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const floatingIcons = [
  { icon: FaPalette, x: "20%", y: "20%", delay: 0 },      // Design
  { icon: FaChartLine, x: "80%", y: "15%", delay: 0.5 },  // Growth
  { icon: FaMobileAlt, x: "15%", y: "60%", delay: 1 },    // Responsive
  { icon: FaLightbulb, x: "85%", y: "65%", delay: 1.5 },  // Ideas
  { icon: FaRegSmile, x: "25%", y: "85%", delay: 2 },     // Satisfaction
  { icon: FaMagic, x: "75%", y: "40%", delay: 2.5 },      // Magic/Transform
  { icon: FaRegHandshake, x: "45%", y: "75%", delay: 3 }  // Partnership
];

const DesignConsultation13 = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    businessDescription: '',
    budget: '',
    projectType: ''
  });

  useEffect(() => {
    setIsMounted(true);
    document.body.setAttribute('data-page', 'landing');
    return () => {
      document.body.removeAttribute('data-page');
    };
  }, []);

  const generateRandomMovement = (duration: number) => ({
    x: [0, -20, 20, -10, 10, 0],
    y: [0, 20, -20, 10, -10, 0],
    transition: {
      duration,
      ease: "linear",
      repeat: Infinity,
      repeatType: "reverse" as const,
    },
  });

  const scrollToForm = () => {
    const formElement = document.getElementById('consultation-form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [faqs, setFaqs] = useState<FAQ[]>([
    {
      question: 'How long does it take to build a website?',
      answer: 'Most projects are completed within 4-8 weeks, depending on the complexity and your requirements.',
      isOpen: false
    },
    {
      question: "What's included in the consultation?",
      answer: 'Our 30-minute consultation includes a thorough discussion of your goals, budget, timeline, and a high-level strategy recommendation.',
      isOpen: false
    },
    {
      question: 'Do you provide ongoing support?',
      answer: 'Yes, we offer various maintenance and support packages to keep your website running smoothly after launch.',
      isOpen: false
    },
    {
      question: "What's your pricing structure?",
      answer: "Our pricing varies based on project requirements. We'll provide a detailed quote after understanding your needs during the consultation.",
      isOpen: false
    }
  ]);

  const toggleFAQ = (index: number) => {
    setFaqs(faqs.map((faq, i) => ({
      ...faq,
      isOpen: i === index ? !faq.isOpen : false
    })));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <PageContainer>
      <SEO 
        title="Web Design Consultation - Transform Your Digital Presence"
        description="Schedule your free web design consultation and discover how we can help your business grow online with a custom-designed website."
        keywords="web design consultation, free consultation, website design, professional web design, custom website, web development"
        url="/design-consultation-13"
        noindex={true}
      />
      <SimpleNavbar>
        <MaxWidthContainer>
          <Logo />
        </MaxWidthContainer>
      </SimpleNavbar>

      <HeroSection>
        <MaxWidthContainer>
          {isMounted && floatingIcons.map((item, index) => {
            const Icon = item.icon;
            return (
              <FloatingIcon
                key={index}
                initial={{ opacity: 0, x: item.x, y: item.y }}
                animate={{
                  opacity: 0.5,
                  ...generateRandomMovement(10 + Math.random() * 5),
                }}
                style={{ left: item.x, top: item.y }}
                transition={{
                  opacity: { duration: 1, delay: item.delay },
                }}
              >
                <Icon />
              </FloatingIcon>
            );
          })}
          <HeroContent>
            <MainHeading>
              Transform Your <GradientText>Online Presence</GradientText> With a High-Converting Website
            </MainHeading>
            <SubHeading>
              Book your free consultation with our expert design team and discover how we can help you create a high-converting website.
            </SubHeading>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CTAButton
                onClick={scrollToForm}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Schedule Your Free Consultation
              </CTAButton>
            </motion.div>
          </HeroContent>
        </MaxWidthContainer>
      </HeroSection>

      <BenefitsSection>
        <MaxWidthContainer>
          <SectionTitle>Why Choose Our Web Design Service?</SectionTitle>
          <BenefitsGrid>
            <BenefitCard>
              <FaRocket />
              <h3>Boost Your Online Presence</h3>
              <p>Stand out from competitors with a stunning, modern website that captures attention and drives engagement.</p>
            </BenefitCard>
            <BenefitCard>
              <FaCheck />
              <h3>Conversion-Focused Design</h3>
              <p>Turn visitors into customers with strategic layouts and compelling calls-to-action that drive results.</p>
            </BenefitCard>
            <BenefitCard>
              <FaClock />
              <h3>Quick Turnaround</h3>
              <p>Get your website up and running in weeks, not months, with our efficient design and development process.</p>
            </BenefitCard>
          </BenefitsGrid>
        </MaxWidthContainer>
      </BenefitsSection>

      <TestimonialsSection>
        <MaxWidthContainer>
          <SectionTitle>What Our Clients Say</SectionTitle>
          <SectionSubtitle>Join hundreds of satisfied clients who've transformed their online presence</SectionSubtitle>
          <TestimonialsGrid>
            <TestimonialCard>
              <div className="stars">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
              <p className="quote">"Working with NeonPixel was a game-changer for our agency. They created a stunning website that perfectly captures our brand and has significantly increased our conversion rates."</p>
              <p className="author">Sarah Johnson</p>
              <p className="position">Brand Digital Marketing</p>
            </TestimonialCard>
            <TestimonialCard>
              <div className="stars">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
              <p className="quote">"The team at NeonPixel delivered exceptional results. Their approach to web design has helped us stand out in a competitive market."</p>
              <p className="author">Michael Chen</p>
              <p className="position">TechWave Solutions</p>
            </TestimonialCard>
            <TestimonialCard>
              <div className="stars">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
              <p className="quote">"Our new website has completely transformed our online presence. We've seen a 300% increase in online bookings since launching."</p>
              <p className="author">Emma Thompson</p>
              <p className="position">Fitness Hub Co.</p>
            </TestimonialCard>
          </TestimonialsGrid>
        </MaxWidthContainer>
      </TestimonialsSection>

      <ProcessSection>
        <MaxWidthContainer>
          <SectionTitle>Our Simple 4-Step Process</SectionTitle>
          <ProcessGrid>
            <ProcessCard>
              <FaComments />
              <h3>1. Discovery Call</h3>
              <p>We discuss your goals, requirements, and vision for your website.</p>
            </ProcessCard>
            <ProcessCard>
              <FaCogs />
              <h3>2. Strategy & Planning</h3>
              <p>We create a detailed roadmap and design strategy for your project.</p>
            </ProcessCard>
            <ProcessCard>
              <FaRocket />
              <h3>3. Design & Development</h3>
              <p>Our team brings your vision to life with pixel-perfect execution.</p>
            </ProcessCard>
            <ProcessCard>
              <FaCheck />
              <h3>4. Launch & Support</h3>
              <p>We ensure a smooth launch and provide ongoing support for your success.</p>
            </ProcessCard>
          </ProcessGrid>
        </MaxWidthContainer>
      </ProcessSection>

      <FAQSection>
        <MaxWidthContainer>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <div>
            {faqs.map((faq, index) => (
              <FAQItem key={index}>
                <h3 onClick={() => toggleFAQ(index)}>
                  {faq.question}
                  <FAQIcon
                    animate={{ rotate: faq.isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <FaChevronDown />
                  </FAQIcon>
                </h3>
                <FAQContent
                  initial={false}
                  animate={{
                    height: faq.isOpen ? 'auto' : 0,
                    opacity: faq.isOpen ? 1 : 0,
                    marginTop: faq.isOpen ? '1rem' : 0
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {faq.answer}
                </FAQContent>
              </FAQItem>
            ))}
          </div>
        </MaxWidthContainer>
      </FAQSection>

      <FormSection id="consultation-form">
        <MaxWidthContainer>
          <FormHeading>Start Your Website Transformation</FormHeading>
          <FormSubHeading>
            Fill out the form below and we'll get back to you within 24 hours to discuss your project in detail
          </FormSubHeading>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">Phone Number</Label>
              <Input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="businessDescription">Brief Description of Your Business</Label>
              <Textarea
                id="businessDescription"
                name="businessDescription"
                value={formData.businessDescription}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="budget">Budget Range</Label>
              <Select
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                required
              >
                <option value="">Select a range</option>
                <option value="2000-5000">$2,000 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000-20000">$10,000 - $20,000</option>
                <option value="20000+">$20,000+</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="projectType">Project Type</Label>
              <Select
                id="projectType"
                name="projectType"
                value={formData.projectType}
                onChange={handleChange}
                required
              >
                <option value="">Select project type</option>
                <option value="webDesign">Web Design</option>
                <option value="webApp">Web Application</option>
              </Select>
            </FormGroup>
            <CTAButton
              type="submit"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ width: '100%' }}
            >
              Schedule Your Free Consultation
            </CTAButton>
            <PrivacyNote>
              <FaShieldAlt style={{ marginRight: '0.5rem', verticalAlign: 'middle' }} />
              We value your privacy and will never share your information
            </PrivacyNote>
          </Form>
        </MaxWidthContainer>
      </FormSection>

      <SimpleFooter>
        <FooterLinks>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </FooterLinks>
        <Copyright>&copy; 2024 NeonPixel Designs. All rights reserved.</Copyright>
      </SimpleFooter>
    </PageContainer>
  );
};

export default DesignConsultation13;
