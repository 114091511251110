import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
`;

const TransitionWrapper = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  position: relative;
  will-change: opacity;
  transform: translateZ(0);
`;

const pageTransitionVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn"
    }
  }
};

export const PageTransition: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [, setIsTransitioning] = useState(false);

  return (
    <PageContainer>
      <AnimatePresence mode="wait" onExitComplete={() => setIsTransitioning(false)}>
        <TransitionWrapper
          key={location.pathname}
          variants={pageTransitionVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onAnimationStart={() => setIsTransitioning(true)}
          layout={false}
        >
          {children}
        </TransitionWrapper>
      </AnimatePresence>
    </PageContainer>
  );
};
