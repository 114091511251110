// Using relative paths for images
const BLOG_IMAGES_PATH = '/images/blog';

export interface BlogPost {
  id: string;
  title: string;
  subtitle: string;
  date: string;
  author: string;
  category: string;
  image: string;
  imageAlt: string;
  content: string;
  excerpt: string;
  readTime: string;
  slug?: string;
}

export const blogPosts: BlogPost[] = [
  {
    id: 'web-development-best-practices',
    title: 'Essential Web Development Best Practices',
    subtitle: 'Building Robust & Scalable Websites',
    date: 'February 20, 2024',
    author: 'NeonPixel Designs',
    category: 'WEB DEVELOPMENT',
    image: `${BLOG_IMAGES_PATH}/web-development-best-practices.jpg`,
    imageAlt: 'Modern development environment showing code and development tools',
    excerpt: 'Learn about building robust & scalable websites through our comprehensive guide to web development best practices, covering performance, security, and maintainability.',
    readTime: '6 min read',
    slug: 'web-development-best-practices',
    content: `
      Learn about crucial web development practices that ensure your website is fast, secure, and maintainable.

      ## Performance Optimization

      Performance is crucial for user experience and SEO. Here are key practices:

      - Minimize HTTP requests
      - Optimize images and assets
      - Implement caching strategies
      - Use lazy loading for images and components
      - Compress and minify code

      ## Security Best Practices

      Protecting your website and user data is paramount:

      - Implement HTTPS
      - Use secure authentication methods
      - Sanitize user inputs
      - Keep dependencies updated
      - Regular security audits

      ## Code Quality & Maintainability

      Writing clean, maintainable code is essential for long-term success:

      - Follow consistent coding standards
      - Write comprehensive documentation
      - Implement automated testing
      - Use version control effectively
      - Regular code reviews

      ## Accessibility

      Ensure your website is accessible to all users:

      - Semantic HTML structure
      - ARIA labels where necessary
      - Keyboard navigation support
      - Color contrast compliance
      - Screen reader compatibility

      ## Performance Monitoring

      Continuously monitor and improve your website:

      - Use analytics tools
      - Monitor server performance
      - Track user behavior
      - Regular performance audits
      - A/B testing for improvements

      Remember: the best practices we've discussed aren't just theoretical concepts – they're practical tools that, when applied thoughtfully, create better experiences for users and developers alike. Whether you're starting a new project or maintaining an existing one, these principles will guide you towards creating more successful, sustainable web applications.
    `
  },
  {
    id: 'glassmorphism-css-effects',
    title: 'The Rise of Glassmorphism: Creating Modern UI Effects with CSS',
    subtitle: '',
    date: 'June 15, 2024',
    author: 'NeonPixel Design Team',
    category: 'WEB DESIGN',
    readTime: '6 min read',
    slug: 'glassmorphism-css-effects',
    excerpt: 'Glassmorphism has emerged as one of the most captivating design trends in modern UI/UX design. This sophisticated effect creates the illusion of frosted glass, adding depth and elegance to web interfaces.',
    image: '',
    imageAlt: '',
    content: `
      Glassmorphism has emerged as one of the most captivating design trends in modern UI/UX design. This sophisticated effect creates the illusion of frosted glass, adding depth and elegance to web interfaces. In this post, we'll explore how to implement this stunning effect using CSS and discuss best practices for its application.

      ## What is Glassmorphism?

      Glassmorphism is a design style that mimics the visual properties of frosted glass. It's characterized by:

      - Semi-transparent backgrounds
      - Subtle border
      - Background blur effect
      - Multi-layered approach with floating elements
      - Soft, subtle shadows

      ## Creating the Glass Effect

      Here's the CSS code to create a basic glassmorphism effect:

      \`\`\`css
      .glass-card {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 2rem;
        box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
      }
      \`\`\`

      ## Live Demo

      Here's how the effect looks in practice:

      [Live demo is implemented in the component itself]

      ## Best Practices

      When implementing glassmorphism in your designs, consider these key points:

      - Contrast: Ensure sufficient contrast between the glass effect and the background for readability.
      - Background: The effect works best with colorful, gradient backgrounds.
      - Content Hierarchy: Use the effect strategically to create depth and focus attention.
      - Performance: Be mindful of backdrop-filter performance on different devices.

      ## Browser Support

      The backdrop-filter property, which is crucial for the glass effect, is supported in most modern browsers. However, it's important to provide fallbacks for browsers that don't support it:

      \`\`\`css
      @supports not (backdrop-filter: blur(10px)) {
        .glass-card {
          background: rgba(255, 255, 255, 0.8);
        }
      }
      \`\`\`

      ## When to Use Glassmorphism

      Glassmorphism is particularly effective for:

      - Modal windows and pop-ups
      - Navigation menus
      - Card components
      - Hero sections with overlaid text
      - Form elements

      ## Conclusion

      Glassmorphism, when used appropriately, can add a sophisticated and modern touch to your web interfaces. The key is to use it judiciously, not overdo it, and ensure it enhances rather than hinders the user experience. As with any design trend, moderation is crucial - use it to highlight important elements rather than applying it throughout your entire interface.

      Ready to implement glassmorphism in your next project? Remember to test across different devices and browsers to ensure a consistent experience for all users. If you need help implementing these modern UI effects in your website, don't hesitate to contact our team at NeonPixel Designs.
    `
  },
  {
    id: 'modern-web-design-trends-2024',
    title: 'Modern Web Design Trends for 2024',
    subtitle: 'Creating Engaging Digital Experiences',
    date: 'February 15, 2024',
    author: 'NeonPixel Designs',
    category: 'WEB DESIGN',
    readTime: '5 min read',
    slug: 'modern-web-design-trends-2024',
    excerpt: 'The landscape of web design is constantly evolving, and 2024 brings exciting new trends that are reshaping how we create digital experiences.',
    image: '',
    imageAlt: '',
    content: `
      The landscape of web design is constantly evolving, and 2024 brings exciting new trends that are reshaping how we create digital experiences. Let's explore the key design elements that are defining modern websites.

      ## Minimalism with Personality

      Whilst minimalism remains popular, designers are adding unique touches to make websites more memorable. Think subtle animations, custom illustrations, and thoughtful micro-interactions that guide users through their journey.

      ## Immersive Animations

      Motion design has become more sophisticated, with smooth transitions and scroll-triggered animations creating engaging storytelling experiences. However, performance remains crucial – animations should enhance, not hinder, the user experience.

      ## Dark Mode & Colour Psychology

      Dark mode isn't just a preference; it's becoming a standard feature. Designers are exploring creative ways to implement colour schemes that work beautifully in both light and dark modes, whilst using colour psychology to evoke specific emotions.

      ## AI-Driven Personalisation

      Artificial intelligence is enabling unprecedented levels of personalisation. From dynamic content arrangement to adaptive user interfaces, websites are becoming more responsive to individual user preferences and behaviours.

      ## Accessibility-First Design

      Inclusive design is no longer an afterthought. Modern websites are being built with accessibility in mind from the start, ensuring that digital experiences are available to everyone, regardless of their abilities.

      ## Key Takeaways:

      - Focus on purposeful animations that enhance user experience
      - Implement dark mode with thoughtful colour considerations
      - Prioritise accessibility in your design process
      - Use AI to create personalised user experiences
      - Maintain performance whilst adding interactive elements

      The future of web design is about creating experiences that are not only visually stunning but also inclusive, performant, and personalised. At NeonPixel Designs, we're excited to help organizations implement these trends in ways that serve their unique goals and audience needs.
    `
  }
];
