import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../../styles/theme';

interface PageHeaderProps {
  title: string;
  subtitle: string;
}

const HeaderContainer = styled.header`
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  text-align: center;
  background: linear-gradient(
    170deg,
    ${theme.colors.backgroundLight} 0%,
    ${theme.colors.background} 100%
  );
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.sm}) {
    height: 250px;
    padding: 0 1.5rem;
  }
`;

const HeaderContent = styled.div`
  max-width: ${theme.breakpoints.wide};
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(motion.h1)`
  font-size: clamp(2rem, 5vw, 3.5rem);
  margin-bottom: 1.5rem;
  background: linear-gradient(
    120deg,
    ${theme.colors.primary},
    ${theme.colors.secondary}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px ${theme.colors.primary}40;
  text-align: center;
`;

const Subtitle = styled(motion.p)`
  font-size: clamp(1.1rem, 3vw, 1.3rem);
  color: ${theme.colors.textSecondary};
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: center;
`;

const GlowOrb = styled(motion.div)`
  position: absolute;
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    ${props => props.color}20 0%,
    ${props => props.color}10 30%,
    transparent 70%
  );
  filter: blur(60px);
  pointer-events: none;
`;

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HeaderContainer>
      <GlowOrb
        color={theme.colors.primary}
        initial={{ opacity: 0, scale: 0.8, x: '60%', y: '-50%' }}
        animate={{ opacity: 0.6, scale: 1 }}
        transition={{ duration: 1.5, ease: 'easeOut' }}
        style={{ top: 0, right: 0 }}
      />
      <GlowOrb
        color={theme.colors.secondary}
        initial={{ opacity: 0, scale: 0.8, x: '-60%', y: '50%' }}
        animate={{ opacity: 0.4, scale: 1 }}
        transition={{ duration: 1.5, ease: 'easeOut' }}
        style={{ bottom: 0, left: 0 }}
      />
      <HeaderContent>
        <Title
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          {title}
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2, ease: 'easeOut' }}
        >
          {subtitle}
        </Subtitle>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default PageHeader;
