import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { blogPosts } from '../data/blogPosts';
import BlogCard from '../components/Blog/BlogCard';
import { PageContainer } from '../components/Layout/PageContainer';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const BlogPage: React.FC = () => {
  // Function to parse date string in "Month DD, YYYY" format
  const parseDate = (dateStr: string) => {
    const months: { [key: string]: number } = {
      'January': 0, 'February': 1, 'March': 2, 'April': 3,
      'May': 4, 'June': 5, 'July': 6, 'August': 7,
      'September': 8, 'October': 9, 'November': 10, 'December': 11
    };
    
    const [month, day, year] = dateStr.split(' ');
    const monthIndex = months[month];
    const dayNum = parseInt(day.replace(',', ''));
    const yearNum = parseInt(year);
    
    return new Date(yearNum, monthIndex, dayNum);
  };

  // Manually order the blog posts in the exact specified order
  const orderedBlogPosts = [
    // Spot 1: Glassmorphism (leftmost)
    blogPosts.find(post => post.id === 'glassmorphism-css-effects'),
    // Spot 2: Modern Web Design Trends (middle)
    blogPosts.find(post => post.id === 'modern-web-design-trends-2024'),
    // Spot 3: Web Development Best Practices (rightmost)
    blogPosts.find(post => post.id === 'web-development-best-practices')
  ].filter((post): post is typeof blogPosts[0] => post !== undefined);

  return (
    <>
      <SEO
        title="Web Design & Development Blog"
        description="Stay updated with the latest web design trends, development tips, and digital marketing insights. Expert articles from our team of professionals."
        keywords="web design blog, development tutorials, UI/UX tips, web development guides, design trends, tech insights"
        url="/blog"
        type="blog"
      />
      <PageContainer>
        <PageHeader
          title="Our Blog"
          subtitle="Insights and updates from our team of experts"
        />
        <Container>
          <BlogGrid>
            {orderedBlogPosts.map((post, index) => (
              <motion.div
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <BlogCard post={post} />
              </motion.div>
            ))}
          </BlogGrid>
        </Container>
      </PageContainer>
    </>
  );
};

export default BlogPage;
