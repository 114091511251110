import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  $noPadding?: boolean;
}

const Container = styled.div<ContainerProps>`
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-top: ${props => props.$noPadding ? '0' : '60px'};
  background-color: ${props => props.theme.colors.background};
  position: relative;
  overflow: visible;
`;

interface PageContainerProps {
  children: React.ReactNode;
  noPadding?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({ 
  children, 
  noPadding
}) => {
  return (
    <Container $noPadding={noPadding}>
      {children}
    </Container>
  );
};
