import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/SEO/SEO';
import { FaRocket, FaCode, FaLightbulb } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const HeroSection = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 4rem 0;

  @media (max-width: 968px) {
    flex-direction: column;
    text-align: center;
    min-height: auto;
  }
`;

const ContentSection = styled.div`
  flex: 1;
`;

const MainHeading = styled(motion.h1)`
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-family: 'Outfit', sans-serif;
  background: linear-gradient(135deg, #00E5FF 0%, #B066FE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subheading = styled.h2`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 2rem;
  font-weight: 400;
  max-width: 600px;
  line-height: 1.6;

  @media (max-width: 968px) {
    margin: 0 auto 2rem;
  }
`;

const Form = styled.form`
  flex: 1;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.03);
  padding: 2.5rem;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(0, 229, 255, 0.1);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 1rem;
  min-height: 120px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(0, 229, 255, 0.1);
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #00E5FF 0%, #B066FE 100%);
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const Feature = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  svg {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    flex-shrink: 0;
  }

  div {
    h3 {
      color: #ffffff;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
    }

    p {
      color: ${props => props.theme.colors.textSecondary};
      font-size: 0.9rem;
      line-height: 1.6;
    }
  }
`;

const PrivacyNote = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.textSecondary};
  font-size: 0.8rem;
  margin-top: 1rem;
`;

const FreeConsultation: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <SEO 
        title="Free Web Design Consultation | NeonPixel Designs"
        description="Schedule your free web design consultation with our expert team. Let's discuss your vision and create a plan for your digital success."
        keywords="free consultation, web design consultation, website consultation, digital strategy, web development planning"
        url="/free-consultation"
        noindex={true}
      />
      <Container>
        <HeroSection>
          <ContentSection>
            <MainHeading
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Let's Build Something Extraordinary
            </MainHeading>
            <Subheading>
              Schedule a consultation with our development team to discuss your vision. 
              We'll explore the possibilities and create a roadmap for your digital success.
            </Subheading>
            <Features>
              <Feature>
                <FaRocket />
                <div>
                  <h3>Strategic Planning</h3>
                  <p>Develop a clear roadmap aligned with your business objectives and target audience.</p>
                </div>
              </Feature>
              <Feature>
                <FaCode />
                <div>
                  <h3>Technical Excellence</h3>
                  <p>Leverage cutting-edge technologies to build robust, scalable solutions.</p>
                </div>
              </Feature>
              <Feature>
                <FaLightbulb />
                <div>
                  <h3>Creative Solutions</h3>
                  <p>Transform your ideas into innovative digital experiences that stand out.</p>
                </div>
              </Feature>
            </Features>
          </ContentSection>

          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="company">Company (Optional)</Label>
              <Input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="message">Tell us about your project</Label>
              <TextArea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="What are your goals? Any specific challenges?"
              />
            </FormGroup>
            <SubmitButton
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Schedule Consultation
            </SubmitButton>
            <PrivacyNote>
              Your information is confidential and will not be shared.
            </PrivacyNote>
          </Form>
        </HeroSection>
      </Container>
    </>
  );
};

export default FreeConsultation;
