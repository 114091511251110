interface TeamMember {
  id: number;
  name: string;
  role: string;
  description: string;
}

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "Damien Rozan",
    role: "Founder & Creative Director",
    description: "With a passion for innovative design and cutting-edge technology, Damien founded NeonPixel Designs to bridge the gap between creativity and functionality. His vision drives our commitment to delivering exceptional digital experiences."
  },
  {
    id: 2,
    name: "Alex Rivera",
    role: "Full Stack Developer",
    description: "Alex brings technical mastery to our development process, specialising in React and modern web technologies."
  },
  {
    id: 3,
    name: "Sophie Chen",
    role: "UI/UX/Graphic Designer",
    description: "Sophie crafts intuitive and beautiful user interfaces that perfectly balance form and function, whilst creating stunning visual assets that elevate brand identities."
  },
  {
    id: 4,
    name: "James Wilson",
    role: "Web Designer",
    description: "James creates beautiful and functional websites that help organisations establish a strong online presence and achieve their digital goals."
  },
  {
    id: 5,
    name: "Emily Parker",
    role: "Front-End Developer",
    description: "Emily specialises in creating responsive and interactive user interfaces, bringing designs to life with optimised code and smooth animations."
  }
];
