import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { theme } from '../../styles/theme';
import Logo from '../Logo/Logo';

interface NavContainerProps {
  $scrolled: boolean;
}

interface TransitionProps {
  $isTransitioning?: boolean;
}

const NavContainer = styled(motion.nav)<NavContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: ${({ $scrolled }) =>
    $scrolled ? theme.colors.background : 'transparent'};
  backdrop-filter: ${({ $scrolled }) =>
    $scrolled ? 'blur(10px)' : 'none'};
  padding: 0.75rem 2rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  transform-origin: left center;
  margin-right: auto;

  @media (max-width: 768px) {
    transform: scale(0.95);
    margin-left: 0;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: ${theme.breakpoints.tablet}) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  font-weight: 500;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0.95rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: ${theme.colors.primary};
    transition: ${theme.transitions.default};
  }

  &:hover:after {
    width: 100%;
  }
`;

const CTAButton = styled(Link)<TransitionProps>`
  padding: 0.4rem 1.25rem;
  background: ${theme.colors.primary};
  color: ${theme.colors.background};
  border-radius: 4px;
  font-weight: 600;
  transition: ${theme.transitions.default};
  box-shadow: 0 0 20px ${theme.colors.primary}40;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  white-space: nowrap;
  height: 34px;
  font-size: 0.95rem;
  pointer-events: ${props => props.$isTransitioning ? 'none' : 'auto'};
  opacity: ${props => props.$isTransitioning ? 0.5 : 1};

  &:hover {
    background: ${theme.colors.secondary};
    color: ${theme.colors.background};
    box-shadow: 0 0 30px ${theme.colors.secondary}40;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: ${theme.colors.text};
  cursor: pointer;
  z-index: 1001;

  @media (max-width: ${theme.breakpoints.tablet}) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  background: ${theme.colors.background};
  padding: 6rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1000;
  box-shadow: -10px 0 30px rgba(0, 0, 0, 0.2);
  
  ${CTAButton} {
    margin: 1rem 0 0;
    justify-content: center;
  }
  
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: none;
  }
`;

const MobileMenuOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: ${theme.colors.text};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const MobileNavLink = styled(Link)`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${theme.colors.text};
  padding: 0.5rem 0;
  border-bottom: 1px solid ${theme.colors.primary}20;
  transition: ${theme.transitions.default};

  &:hover {
    color: ${theme.colors.primary};
    border-bottom-color: ${theme.colors.primary};
  }
`;

const navVariants = {
  hidden: { y: -100 },
  visible: { y: 0 }
};

const menuVariants = {
  closed: {
    x: "100%",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  },
  open: {
    x: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const overlayVariants = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.2
    }
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.2
    }
  }
};

export const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const location = useLocation();
  const isConsultationPage = location.pathname === '/free-consultation' || location.pathname === '/design-consultation';

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mobileMenuOpen]);

  const handleCTAClick = (e: React.MouseEvent) => {
    if (isTransitioning) {
      e.preventDefault();
      return;
    }
    setIsTransitioning(true);
    // Reset after transition
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
      <NavContainer
        $scrolled={scrolled}
        variants={navVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6 }}
      >
        <NavContent>
          <LogoContainer to="/">
            <Logo width="280px" height="35px" />
          </LogoContainer>
          {!isConsultationPage && (
            <>
              <NavLinks>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/about">About</NavLink>
                <NavLink to="/portfolio">Portfolio</NavLink>
                <NavLink to="/services">Services</NavLink>
                <NavLink to="/blog">Blog</NavLink>
                <NavLink to="/contact">Contact</NavLink>
                <CTAButton 
                  to="/contact" 
                  onClick={handleCTAClick}
                  $isTransitioning={isTransitioning}
                >
                  Start Your Project
                </CTAButton>
              </NavLinks>
              <MobileMenuButton 
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                aria-label="Toggle mobile menu"
              >
                {mobileMenuOpen ? '✕' : '☰'}
              </MobileMenuButton>
            </>
          )}
        </NavContent>
      </NavContainer>

      <AnimatePresence>
        {mobileMenuOpen && !isConsultationPage && (
          <>
            <MobileMenuOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeMobileMenu}
            />
            <MobileMenu
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <CloseButton onClick={closeMobileMenu}>×</CloseButton>
              <MobileNavLink to="/" onClick={closeMobileMenu}>Home</MobileNavLink>
              <MobileNavLink to="/about" onClick={closeMobileMenu}>About</MobileNavLink>
              <MobileNavLink to="/portfolio" onClick={closeMobileMenu}>Portfolio</MobileNavLink>
              <MobileNavLink to="/services" onClick={closeMobileMenu}>Services</MobileNavLink>
              <MobileNavLink to="/blog" onClick={closeMobileMenu}>Blog</MobileNavLink>
              <MobileNavLink to="/contact" onClick={closeMobileMenu}>Contact</MobileNavLink>
              <CTAButton to="/contact" onClick={closeMobileMenu} $isTransitioning={isTransitioning}>Start Your Project</CTAButton>
            </MobileMenu>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
