import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { services } from '../../data/services';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaCode, FaPalette, FaMobileAlt, FaRocket, FaCog, FaChartLine } from 'react-icons/fa';

const ServicesSection = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.background};
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const ServiceCard = styled(motion.div)`
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 20px;
  padding: 2.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.primary}20;
  transition: all 0.3s ease;
`;

const ServiceIcon = styled.div`
  width: 60px;
  height: 60px;
  background: ${props => props.theme.colors.background};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  position: relative;
  z-index: 1;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.text};
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  position: relative;
`;

const Feature = styled.li`
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  
  &:before {
    content: "•";
    color: ${props => props.theme.colors.primary};
    margin-right: 0.5rem;
  }
`;

const LearnMore = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  display: inline-block;
  margin-top: 1rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const GlowEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 0%,
    ${props => props.theme.colors.primary}10 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  
  ${ServiceCard}:hover & {
    opacity: 1;
  }
`;

interface ServicesProps {
  className?: string;
  showFeatures?: boolean;
}

const Services: React.FC<ServicesProps> = ({ className, showFeatures = false }) => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      setIsAnimating(false);
    };
  }, []);

  return (
    <ServicesSection className={className}>
      <ServicesContainer>
        {isMounted && (
          <ServicesGrid>
            {services.map((service, index) => {
              const Icon = service.icon;
              return (
                <ServiceCard
                  key={`service-${index}`}
                  onClick={() => navigate(service.path)}
                  initial={{ opacity: 0, y: 20 }}
                  animate={isMounted ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                  transition={{ 
                    duration: 0.5, 
                    delay: index * 0.1,
                    ease: "easeOut" 
                  }}
                  viewport={{ once: true }}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
                  }}
                  onAnimationStart={() => setIsAnimating(true)}
                  onAnimationComplete={() => setIsAnimating(false)}
                >
                  <ServiceIcon>
                    <Icon />
                  </ServiceIcon>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                  {showFeatures && service.features && (
                    <FeaturesList>
                      {service.features.map((feature, i) => (
                        <Feature key={i}>{feature}</Feature>
                      ))}
                    </FeaturesList>
                  )}
                  <LearnMore>Learn More →</LearnMore>
                  <GlowEffect />
                </ServiceCard>
              );
            })}
          </ServicesGrid>
        )}
      </ServicesContainer>
    </ServicesSection>
  );
};

export { Services };
