import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles/GlobalStyles';
import { theme } from './styles/theme';
import { Navbar } from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import ServicesPage from './pages/ServicesPage';
import BlogPage from './pages/BlogPage';
import WebDesign from './pages/WebDesign';
import WebDevelopment from './pages/WebDevelopment';
import WebDevelopmentBestPractices from './pages/blog/web-development-best-practices';
import ModernWebDesignTrends from './pages/blog/modern-web-design-trends-2024';
import GlassmorphismEffects from './pages/blog/glassmorphism-css-effects';
import FreeConsultation from './pages/FreeConsultation';
import DesignConsultation from './pages/DesignConsultation';
import DesignConsultation12 from './pages/DesignConsultation12';
import DesignConsultation13 from './pages/DesignConsultation13';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import { PageTransition } from './components/Layout/PageTransition';

const AppRoutes = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/design-consultation-12' || location.pathname === '/design-consultation-13';

  return (
    <>
      {!isLandingPage && <Navbar />}
      <PageTransition>
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/web-development-best-practices" element={<WebDevelopmentBestPractices />} />
          <Route path="/blog/modern-web-design-trends-2024" element={<ModernWebDesignTrends />} />
          <Route path="/blog/glassmorphism-css-effects" element={<GlassmorphismEffects />} />
          <Route path="/services/web-design" element={<WebDesign />} />
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/free-consultation" element={<FreeConsultation />} />
          <Route path="/design-consultation" element={<DesignConsultation />} />
          <Route path="/design-consultation-12" element={<DesignConsultation12 />} />
          <Route path="/design-consultation-13" element={<DesignConsultation13 />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
        </Routes>
      </PageTransition>
      {!isLandingPage && <Footer />}
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        <main>
          <AppRoutes />
        </main>
      </ThemeProvider>
    </Router>
  );
};

export default App;
