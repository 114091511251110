import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import { teamMembers } from '../data/teamMembers';
import SEO from '../components/SEO/SEO';

const PageContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Section = styled.section`
  margin: 6rem 0;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
`;

const TeamMemberCard = styled(motion.div)`
  background: ${props => props.theme.colors.backgroundLight};
  border-radius: 12px;
  overflow: hidden;
  padding: 2rem;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.primary}33;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }
`;

const MemberName = styled.h3`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  font-family: 'Outfit', sans-serif;
`;

const MemberRole = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
`;

const MemberDescription = styled.p`
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 1.6;
  font-family: 'Inter', sans-serif;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  font-family: 'Outfit', sans-serif;
  text-align: center;
`;

const SectionText = styled.p`
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

const AboutPage: React.FC = () => {
  return (
    <>
      <SEO
        title="About Our Creative Digital Agency"
        description="Meet the passionate team behind NeonPixel Designs. We're a diverse group of designers, developers, and digital strategists committed to creating exceptional digital experiences."
        keywords="web design team, creative agency team, digital agency professionals, web development experts, UI/UX designers, NeonPixel team"
        url="/about"
        type="company"
      />
      <PageContainer>
        <PageHeader
          title="About Us"
          subtitle="Meet the creative minds behind NeonPixel Designs"
        />
        <Container>
          <Section>
            <SectionTitle>Our Story</SectionTitle>
            <SectionText>
              Founded in 2023, NeonPixel Designs emerged from a passion for creating
              exceptional digital experiences. We believe that great design is more
              than just aesthetics – it's about solving problems and creating
              meaningful connections between brands and their audiences.
            </SectionText>
            <SectionText>
              Our team of creative professionals combines technical expertise with
              artistic vision to deliver cutting-edge solutions that help organisations
              thrive in the digital landscape. We're driven by innovation,
              attention to detail, and a commitment to excellence in everything we do.
            </SectionText>
          </Section>

          <Section>
            <SectionTitle>Our Values</SectionTitle>
            <SectionText>
              We push boundaries and embrace new technologies to create cutting-edge solutions.
              We harmonise artistry with functionality to deliver exceptional digital experiences.
              We work closely with our clients, treating their success as our own.
              We maintain agile processes to deliver high-quality results efficiently.
            </SectionText>
          </Section>

          <Section>
            <SectionTitle>Our Team</SectionTitle>
            <TeamGrid>
              {teamMembers.map((member) => (
                <TeamMemberCard
                  key={member.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <MemberName>{member.name}</MemberName>
                  <MemberRole>{member.role}</MemberRole>
                  <MemberDescription>{member.description}</MemberDescription>
                </TeamMemberCard>
              ))}
            </TeamGrid>
          </Section>
        </Container>
      </PageContainer>
    </>
  );
};

export default AboutPage;
