import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/SEO/SEO';

const ConsultationContainer = styled(motion.div)`
  min-height: 100vh;
  background: linear-gradient(135deg, 
    ${props => props.theme.colors.background} 0%,
    rgba(45, 10, 65, 1) 100%
  );
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const HeroSection = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
`;

const MainHeading = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: ${props => props.theme.colors.text};
  
  span {
    background: linear-gradient(135deg,
      ${props => props.theme.colors.primary},
      ${props => props.theme.colors.secondary}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SubHeading = styled.h2`
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  font-weight: 400;
  margin-bottom: 2.5rem;
  color: ${props => props.theme.colors.textSecondary};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const BenefitsSection = styled.div`
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  color: ${props => props.theme.colors.text};

  &:before {
    content: "✨";
    color: ${props => props.theme.colors.primary};
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }
`;

const BenefitTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
`;

const BenefitText = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  line-height: 1.6;
`;

const FormSection = styled.div`
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const FormTitle = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;
  text-align: center;
`;

const FormSubtitle = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.6;
`;

const ConsultationForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}40;
  }
`;

const TextArea = styled.textarea`
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}40;
  }
`;

const Select = styled.select`
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}40;
  }

  option {
    background: ${props => props.theme.colors.backgroundDark};
    color: ${props => props.theme.colors.text};
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 1rem 2rem;
  background: linear-gradient(135deg,
    ${props => props.theme.colors.primary},
    ${props => props.theme.colors.secondary}
  );
  border: none;
  border-radius: 30px;
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  width: 100%;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;

const SuccessMessage = styled(motion.div)`
  background: ${props => props.theme.colors.success}20;
  border: 1px solid ${props => props.theme.colors.success};
  color: ${props => props.theme.colors.success};
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
`;

const SocialProof = styled.div`
  text-align: center;
  margin: 2rem 0;
  color: ${props => props.theme.colors.textSecondary};
`;

const StarRating = styled.div`
  color: #FFD700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const benefits = [
  {
    title: "Expert Consultation",
    text: "Get personalized advice from our experienced web design professionals"
  },
  {
    title: "Custom Solutions",
    text: "Tailored web design and development solutions for your unique business needs"
  },
  {
    title: "Conversion-Focused",
    text: "Strategies to turn your website visitors into paying customers"
  },
  {
    title: "Modern Technology",
    text: "Built with the latest web technologies for optimal performance"
  },
  {
    title: "SEO-Optimized",
    text: "Implement best practices to improve your search engine rankings"
  },
  {
    title: "Ongoing Support",
    text: "Dedicated support and maintenance to keep your website running smoothly"
  }
];

const DesignConsultation: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    console.log('DesignConsultation mounted, SEO should render');
    return () => setIsMounted(false);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData as any).toString(),
    })
      .then(() => {
        setIsSubmitted(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <ConsultationContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SEO 
        title="Design Consultation | NeonPixel Designs"
        description="Book your design consultation with our expert team. We'll help bring your vision to life with our professional web design services."
        keywords="design consultation, web design consultation, website design, professional web design, custom website design"
        url="/design-consultation"
        noindex={true}
      />
      <ContentWrapper>
        <HeroSection>
          <MainHeading>
            Transform Your Online Presence with
            <span> Expert Web Design</span>
          </MainHeading>
          <SubHeading>
            Book your free 30-minute consultation and discover how we can create a stunning, 
            high-performance website that elevates your brand
          </SubHeading>
        </HeroSection>

        <Grid>
          <BenefitsSection>
            <BenefitsList>
              {benefits.map((benefit, index) => (
                <BenefitItem
                  key={index}
                  as={motion.div}
                  initial={{ opacity: 0, x: -20 }}
                  animate={isMounted ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div>
                    <BenefitTitle>{benefit.title}</BenefitTitle>
                    <BenefitText>{benefit.text}</BenefitText>
                  </div>
                </BenefitItem>
              ))}
            </BenefitsList>
            <SocialProof>
              <StarRating>⭐⭐⭐⭐⭐</StarRating>
              <p>Trusted by 100+ businesses • 5-Star rated on Clutch</p>
            </SocialProof>
          </BenefitsSection>

          <FormSection>
            <FormTitle>Book Your Free Consultation</FormTitle>
            <FormSubtitle>
              Take the first step towards transforming your online presence
            </FormSubtitle>
            
            <ConsultationForm
              onSubmit={handleSubmit}
              name="design-consultation"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              as={motion.form}
              initial={{ opacity: 0, y: 20 }}
              animate={isMounted ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            >
              <AnimatePresence>
                {isSubmitted && (
                  <SuccessMessage
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    Thanks! We'll be in touch within 24 hours to schedule your consultation.
                  </SuccessMessage>
                )}
              </AnimatePresence>

              <input type="hidden" name="form-name" value="design-consultation" />
              <p hidden>
                <label>
                  Don't fill this out if you're human: <input name="bot-field" />
                </label>
              </p>

              <FormGroup>
                <Label htmlFor="name">Full Name</Label>
                <Input type="text" id="name" name="name" required />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="email">Business Email</Label>
                <Input type="email" id="email" name="email" required />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="company">Company Name</Label>
                <Input type="text" id="company" name="company" required />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="website">Website (if any)</Label>
                <Input type="url" id="website" name="website" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="projectType">Project Type</Label>
                <Select id="projectType" name="projectType" required>
                  <option value="">Select project type</option>
                  <option value="website">Website Design</option>
                  <option value="webapp">Web Application</option>
                  <option value="redesign">Website Redesign</option>
                  <option value="development">Custom Development</option>
                  <option value="other">Other</option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="message">Project Goals & Requirements</Label>
                <TextArea id="message" name="message" required />
              </FormGroup>

              <SubmitButton
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Book Free Consultation
              </SubmitButton>
            </ConsultationForm>
          </FormSection>
        </Grid>
      </ContentWrapper>
    </ConsultationContainer>
  );
};

export default DesignConsultation;
