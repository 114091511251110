import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: ${props => props.theme.colors.background};
  padding: 1.5rem 2rem;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Links = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
  
  a {
    color: ${props => props.theme.colors.textSecondary};
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const Copyright = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  font-size: 0.9rem;
  text-align: center;
`;

const LandingFooter = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Links>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </Links>
        <Copyright>
          © 2024 NeonPixel Designs. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default LandingFooter;
