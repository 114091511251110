import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { FaReact, FaNode, FaDatabase, FaCloud, FaCode, FaCog } from 'react-icons/fa';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
`;

const Container = styled.div`
  max-width: ${props => props.theme.breakpoints.wide};
  margin: 0 auto;
  padding: 4rem 2rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 1rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 4rem;
`;

const ServiceCard = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(40, 40, 40, 0.6);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`;

const ProcessSection = styled.div`
  margin-top: 4rem;
`;

const ProcessGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProcessStep = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: start;
`;

const StepNumber = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  font-weight: bold;
`;

const StepContent = styled.div``;

const StepTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const TechStackSection = styled.div`
  margin-top: 4rem;
`;

const TechGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
`;

const TechCard = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(40, 40, 40, 0.6);
  }
`;

const TechIcon = styled.div`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const TechTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const TechDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const WebDevelopment = () => {
  const pageTitle = "Professional Web Development Services";
  const pageDescription = "Expert web development services for scalable and robust applications. We build end-to-end solutions using React, Node.js, and cloud technologies to grow your business.";
  const pageKeywords = "web development, full stack development, API development, database design, cloud integration, React development, Node.js, TypeScript, AWS, UK web development, London web development";
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Web Development Services",
    "provider": {
      "@type": "Organization",
      "name": "NeonPixel Designs",
      "url": "https://neonpixeldesigns.com"
    },
    "description": pageDescription,
    "serviceType": "Web Development",
    "areaServed": "United Kingdom",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock"
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Web Development Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Full-Stack Development",
            "description": "End-to-end web application development with seamless integration between front-end and back-end systems."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "API Development",
            "description": "Creating robust and scalable APIs that power your applications and enable third-party integrations."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Database Design",
            "description": "Efficient database architecture and optimization for reliable data management and quick access."
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Cloud Integration",
            "description": "Deploying and managing applications on cloud platforms for maximum scalability and reliability."
          }
        }
      ]
    }
  };

  const services = [
    {
      title: "Full-Stack Development",
      description: "End-to-end web application development with seamless integration between front-end and back-end systems."
    },
    {
      title: "API Development",
      description: "Creating robust and scalable APIs that power your applications and enable third-party integrations."
    },
    {
      title: "Database Design",
      description: "Efficient database architecture and optimization for reliable data management and quick access."
    },
    {
      title: "Cloud Integration",
      description: "Deploying and managing applications on cloud platforms for maximum scalability and reliability."
    }
  ];

  const processSteps = [
    {
      number: "1",
      title: "Requirements Analysis",
      description: "Deep dive into your project needs, technical requirements, and business objectives to create a comprehensive development plan."
    },
    {
      number: "2",
      title: "Architecture Design",
      description: "Creating a scalable and maintainable architecture that supports your application's current and future needs."
    },
    {
      number: "3",
      title: "Development & Testing",
      description: "Agile development process with continuous integration and testing to ensure high-quality code and functionality."
    },
    {
      number: "4",
      title: "Deployment & Monitoring",
      description: "Smooth deployment process with continuous monitoring and performance optimization."
    }
  ];

  const techStack = [
    {
      icon: <FaReact />,
      title: "React & Next.js",
      description: "Modern UI frameworks"
    },
    {
      icon: <FaCode />,
      title: "TypeScript & JavaScript",
      description: "Type-safe development"
    },
    {
      icon: <FaDatabase />,
      title: "Firebase & NoSQL",
      description: "Cloud databases"
    },
    {
      icon: <FaNode />,
      title: "Node.js & Express",
      description: "Backend development"
    },
    {
      icon: <FaCog />,
      title: "REST APIs & GraphQL",
      description: "API integration"
    },
    {
      icon: <FaCloud />,
      title: "AWS & Cloud Services",
      description: "Cloud infrastructure"
    }
  ];

  return (
    <>
      <SEO
        title="Web Development & Technical Solutions"
        description="Professional web development services specializing in React, Node.js, and modern web technologies. We build scalable, secure, and high-performance web applications."
        keywords="react development, nodejs development, full stack development, web application development, API integration, database development, cloud solutions"
        url="/services/web-development"
        type="service"
      />
      <PageContainer>
        <PageHeader 
          title="Web Development Services"
          subtitle="Building scalable and robust web applications"
        />

        <Container>
          <ServicesGrid>
            {services.map((service, index) => (
              <ServiceCard key={index}>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceCard>
            ))}
          </ServicesGrid>

          <ProcessSection>
            <SectionTitle>Development Process</SectionTitle>
            <ProcessGrid>
              {processSteps.map((step, index) => (
                <ProcessStep key={index}>
                  <StepNumber>{step.number}</StepNumber>
                  <StepContent>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </StepContent>
                </ProcessStep>
              ))}
            </ProcessGrid>
          </ProcessSection>

          <TechStackSection>
            <SectionTitle>Our Tech Stack</SectionTitle>
            <TechGrid>
              {techStack.map((tech, index) => (
                <TechCard key={index}>
                  <TechIcon>{tech.icon}</TechIcon>
                  <TechTitle>{tech.title}</TechTitle>
                  <TechDescription>{tech.description}</TechDescription>
                </TechCard>
              ))}
            </TechGrid>
          </TechStackSection>
        </Container>
      </PageContainer>
    </>
  );
};

export default WebDevelopment;
