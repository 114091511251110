import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../../components/SEO/SEO';
import { Link } from 'react-router-dom';

const BlogContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.text};
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Category = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.text};
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.textSecondary};
`;

const MetaInfo = styled.div`
  display: flex;
  gap: 1rem;
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 2rem;
  font-size: 0.9rem;
`;

const Content = styled.div`
  line-height: 1.8;
  
  h2 {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    margin: 2.5rem 0 1rem;
  }
  
  p {
    margin-bottom: 1.5rem;
  }
  
  ul {
    list-style-type: none;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
    
    li {
      position: relative;
      margin-bottom: 0.75rem;
      
      &:before {
        content: "•";
        color: ${props => props.theme.colors.primary};
        position: absolute;
        left: -1.5rem;
      }
    }
  }
`;

const CodeBlock = styled.pre`
  background: ${props => props.theme.colors.backgroundDark};
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1.5rem 0;
  overflow-x: auto;
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  
  code {
    color: ${props => props.theme.colors.text};
  }
`;

const DemoContainer = styled.div`
  width: 100%;
  height: 300px;
  background: linear-gradient(45deg, #e100ff, #7f00ff);
  border-radius: 12px;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .glass-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2rem;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    color: white;
    text-align: center;
  }
`;

const GlassmorphismEffects = () => {
  return (
    <>
      <SEO 
        title="The Rise of Glassmorphism: Creating Modern UI Effects with CSS | NeonPixel"
        description="Learn how to implement stunning glassmorphism effects using CSS and discover best practices for modern UI design."
        keywords="glassmorphism, CSS effects, UI design, modern web design, backdrop-filter, glass effect"
        url="/blog/glassmorphism-css-effects"
        type="article"
      />
      
      <BlogContainer>
        <BackLink to="/blog">← Back to Blog</BackLink>
        
        <Category>WEB DESIGN</Category>
        <Title>The Rise of Glassmorphism: Creating Modern UI Effects with CSS</Title>
        
        <MetaInfo>
          <span>June 15, 2024</span>
          <span>•</span>
          <span>6 min read</span>
          <span>•</span>
          <span>by NeonPixel Design Team</span>
        </MetaInfo>

        <Content>
          <p>
            Glassmorphism has emerged as one of the most captivating design trends in modern UI/UX design. This sophisticated effect creates the illusion of frosted glass, adding depth and elegance to web interfaces. In this post, we'll explore how to implement this stunning effect using CSS and discuss best practices for its application.
          </p>

          <h2>What is Glassmorphism?</h2>
          <p>
            Glassmorphism is a design style that mimics the visual properties of frosted glass. It's characterized by:
          </p>
          <ul>
            <li>Semi-transparent backgrounds</li>
            <li>Subtle border</li>
            <li>Background blur effect</li>
            <li>Multi-layered approach with floating elements</li>
            <li>Soft, subtle shadows</li>
          </ul>

          <h2>Creating the Glass Effect</h2>
          <p>Here's the CSS code to create a basic glassmorphism effect:</p>
          <CodeBlock>
            <code>{`.glass-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}`}</code>
          </CodeBlock>

          <h2>Live Demo</h2>
          <p>Here's how the effect looks in practice:</p>
          <DemoContainer>
            <div className="glass-card">
              <h3>Glass Effect</h3>
              <p>This is a demonstration of the glassmorphism effect using CSS</p>
            </div>
          </DemoContainer>

          <h2>Best Practices</h2>
          <p>When implementing glassmorphism in your designs, consider these key points:</p>
          <ul>
            <li>Contrast: Ensure sufficient contrast between the glass effect and the background for readability.</li>
            <li>Background: The effect works best with colorful, gradient backgrounds.</li>
            <li>Content Hierarchy: Use the effect strategically to create depth and focus attention.</li>
            <li>Performance: Be mindful of backdrop-filter performance on different devices.</li>
          </ul>

          <h2>Browser Support</h2>
          <p>
            The backdrop-filter property, which is crucial for the glass effect, is supported in most modern browsers. However, it's important to provide fallbacks for browsers that don't support it:
          </p>
          <CodeBlock>
            <code>{`@supports not (backdrop-filter: blur(10px)) {
  .glass-card {
    background: rgba(255, 255, 255, 0.8);
  }
}`}</code>
          </CodeBlock>

          <h2>When to Use Glassmorphism</h2>
          <p>Glassmorphism is particularly effective for:</p>
          <ul>
            <li>Modal windows and pop-ups</li>
            <li>Navigation menus</li>
            <li>Card components</li>
            <li>Hero sections with overlaid text</li>
            <li>Form elements</li>
          </ul>

          <h2>Conclusion</h2>
          <p>
            Glassmorphism, when used appropriately, can add a sophisticated and modern touch to your web interfaces. The key is to use it judiciously, not overdo it, and ensure it enhances rather than hinders the user experience. As with any design trend, moderation is crucial - use it to highlight important elements rather than applying it throughout your entire interface.
          </p>
          <p>
            Ready to implement glassmorphism in your next project? Remember to test across different devices and browsers to ensure a consistent experience for all users. If you need help implementing these modern UI effects in your website, don't hesitate to contact our team at NeonPixel Designs.
          </p>
        </Content>
      </BlogContainer>
    </>
  );
};

export default GlassmorphismEffects;
