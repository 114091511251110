import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { theme } from '../styles/theme';
import { portfolioImages } from '../assets/portfolio/images';

const Container = styled.div`
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0 4rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const FilterButton = styled.button<{ active: boolean }>`
  background: ${props => props.active ? 
    `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.secondary})` : 
    theme.colors.backgroundLight};
  border: 1px solid ${props => props.active ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};
  color: ${theme.colors.text};
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;

  &:hover {
    background: ${props => !props.active && 'rgba(255, 255, 255, 0.1)'};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

const ProjectGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ProjectCard = styled(motion.div)`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: ${theme.colors.backgroundLight};
  height: 300px;

  &:hover {
    .project-overlay {
      opacity: 1;
    }

    img {
      transform: scale(1.05);
    }
  }
`;

const ProjectOverlay = styled.div.attrs({ className: 'project-overlay' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.8)
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ProjectTitle = styled.h3`
  color: #ffffff;
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
  font-family: 'Outfit', sans-serif;
`;

const ProjectDescription = styled.p`
  color: #ffffff;
  font-size: 1rem;
  margin: 0;
  opacity: 0.8;
`;

const ProjectCategory = styled.span`
  color: ${theme.colors.primary};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const ImageWithFallback = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Project: React.FC<{ project: any }> = ({ project }) => (
  <ProjectCard
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <ImageWithFallback
      src={project.image}
      alt={project.altText}
      height="300px"
      loading="lazy"
    />
    <ProjectOverlay>
      <ProjectCategory>{project.category}</ProjectCategory>
      <ProjectTitle>{project.title}</ProjectTitle>
      <ProjectDescription>{project.description}</ProjectDescription>
    </ProjectOverlay>
  </ProjectCard>
);

const projects = [
  {
    id: 1,
    title: "City Dental Care",
    category: "Web Development",
    image: portfolioImages.cityDental,
    altText: "Modern dental clinic website showcasing professional dental services and patient care",
    description: "A modern website for a dental practice, featuring online booking and patient resources."
  },
  {
    id: 2,
    title: "Luxury Realty",
    category: "Web Design",
    image: portfolioImages.luxuryRealty,
    altText: "Elegant real estate website displaying luxury properties and virtual tours",
    description: "High-end real estate website with virtual tours and property listings."
  },
  {
    id: 3,
    title: "Metro Fitness",
    category: "Web Development",
    image: portfolioImages.metroFitness,
    altText: "Dynamic fitness center website with class schedules and membership information",
    description: "Fitness center website with class booking and membership management."
  },
  {
    id: 4,
    title: "Artisan Coffee",
    category: "E-commerce",
    image: portfolioImages.artisanCoffee,
    altText: "Artisanal coffee shop website with online ordering and coffee subscription services",
    description: "E-commerce platform for a specialty coffee roaster."
  },
  {
    id: 5,
    title: "Downtown Law",
    category: "Web Design",
    image: portfolioImages.downtownLaw,
    altText: "Professional law firm website with practice areas and attorney profiles",
    description: "Professional website for a law firm with case management."
  },
  {
    id: 6,
    title: "Fresh Market",
    category: "E-commerce",
    image: portfolioImages.freshMarket,
    altText: "Fresh produce market website with online ordering and delivery services",
    description: "Local market with online ordering and delivery."
  },
  {
    id: 7,
    title: "Elite Auto",
    category: "Web Development",
    image: portfolioImages.eliteAuto,
    altText: "Luxury car dealership website featuring vehicle inventory and booking system",
    description: "Auto dealership with virtual showroom and booking."
  },
  {
    id: 8,
    title: "Serenity Spa",
    category: "Web Design",
    image: portfolioImages.serenitySpa,
    altText: "Relaxing spa website with service menu and online booking capabilities",
    description: "Spa and wellness center with online booking."
  },
  {
    id: 9,
    title: "Harbor View",
    category: "Web Development",
    image: portfolioImages.harborView,
    altText: "Waterfront restaurant website with menu and reservation system",
    description: "Restaurant website with reservation system."
  },
  {
    id: 10,
    title: "Green Thumb",
    category: "E-commerce",
    image: portfolioImages.greenThumb,
    altText: "Garden center website with plant catalog and gardening supplies",
    description: "Garden center with online plant shop."
  },
  {
    id: 11,
    title: "Paws & Claws",
    category: "Web Design",
    image: portfolioImages.pawsClaws,
    altText: "Pet grooming and veterinary services website with appointment booking",
    description: "Pet care services with online scheduling."
  },
  {
    id: 12,
    title: "Classic Barber",
    category: "Web Development",
    image: portfolioImages.classicBarber,
    altText: "Traditional barbershop website with service menu and online appointments",
    description: "Barbershop with appointment booking system."
  }
];

const categories = ["All", "Web Development", "Web Design", "E-commerce"];

const PortfolioPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredProjects = selectedCategory === "All"
    ? projects
    : projects.filter(project => project.category === selectedCategory);

  return (
    <>
      <SEO 
        title="Web Design & Development Portfolio | NeonPixel Designs"
        description="Explore our diverse portfolio of stunning websites, e-commerce solutions, and digital experiences. See how we've helped businesses transform their online presence with modern web design and development."
        keywords="web design portfolio, web development projects, website examples, e-commerce websites, responsive design, UI/UX portfolio, digital agency work, custom website development, modern web design, business websites"
        url="/portfolio"
        type="website"
      />
      <PageHeader
        title="Our Portfolio"
        subtitle="Showcasing our best work and creative solutions"
      />
      <Container>
        <FilterContainer>
          {categories.map(category => (
            <FilterButton
              key={category}
              active={selectedCategory === category}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </FilterButton>
          ))}
        </FilterContainer>
        <AnimatePresence mode="wait">
          <ProjectGrid
            key={selectedCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            {filteredProjects.map(project => (
              <Project key={project.id} project={project} />
            ))}
          </ProjectGrid>
        </AnimatePresence>
      </Container>
    </>
  );
};

export default PortfolioPage;
