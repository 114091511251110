import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { BlogPost } from '../../data/blogPosts';

interface BlogCardProps {
  post: BlogPost;
}

const Card = styled(motion.article)`
  background: ${props => props.theme.colors.background};
  border: 1px solid ${props => props.theme.colors.primary}33;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 200px;
  background: linear-gradient(135deg, #1e3a5f 0%, #481b3d 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
`;

const HeaderTitle = styled.div`
  color: #ffffff;
  font-size: 1.125rem;
  font-family: 'Outfit', sans-serif;
  line-height: 1.4;
  max-width: 90%;
  margin: 0 auto;
`;

const Content = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Category = styled.span`
  color: #00E5FF;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0 0 1rem;
  font-family: 'Outfit', sans-serif;
`;

const Excerpt = styled.p`
  color: #cccccc;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex: 1;
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 0.9rem;
  margin-top: auto;
`;

const CardContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
`;

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
  return (
    <CardContainer to={`/blog/${post.slug || post.id}`}>
      <Card
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.2 }}
      >
        <Header>
          <HeaderTitle>{post.title}</HeaderTitle>
        </Header>
        <Content>
          <Category>{post.category}</Category>
          <Title>{post.title}</Title>
          <Excerpt>{post.excerpt}</Excerpt>
          <Meta>
            <span>{post.date}</span>
            <span>{post.readTime}</span>
          </Meta>
        </Content>
      </Card>
    </CardContainer>
  );
};

export default BlogCard;
