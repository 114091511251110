import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { theme } from '../styles/theme';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${theme.colors.background};
`;

const ContentSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: ${theme.colors.text};
`;

const Section = styled(motion.div)`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: ${theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const SubSection = styled.div`
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  color: ${theme.colors.textSecondary};
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  color: ${theme.colors.textSecondary};
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const LastUpdated = styled.p`
  color: ${theme.colors.textSecondary};
  font-style: italic;
  margin-top: 3rem;
  text-align: center;
`;

const TermsOfServicePage = () => {
  return (
    <>
      <SEO
        title="Terms of Service"
        description="NeonPixel Designs terms of service - Read about our terms and conditions for using our web design and development services."
        keywords="terms of service, terms and conditions, service terms, legal terms, website terms, usage terms"
        url="/terms-of-service"
        type="legal"
      />
      <PageContainer>
        <PageHeader
          title="Terms of Service"
          subtitle="Please read these terms carefully before using our services"
        />
        <ContentSection>
          <Section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Paragraph>
              Welcome to NeonPixel. By accessing our website and using our services, you agree to be bound 
              by these Terms of Service. If you disagree with any part of these terms, please do not use our services.
            </Paragraph>

            <SectionTitle>1. Services Overview</SectionTitle>
            <SubSection>
              <Paragraph>
                NeonPixel provides web design and development services, including but not limited to:
              </Paragraph>
              <List>
                <li>Website design and development</li>
                <li>User interface and experience design</li>
                <li>Web application development</li>
                <li>Website maintenance and support</li>
                <li>Digital consulting services</li>
              </List>
            </SubSection>

            <SectionTitle>2. Client Obligations</SectionTitle>
            <SubSection>
              <Paragraph>
                As our client, you agree to:
              </Paragraph>
              <List>
                <li>Provide accurate and complete information for your project</li>
                <li>Review and provide feedback in a timely manner</li>
                <li>Pay all fees as agreed upon in the project proposal</li>
                <li>Obtain any necessary third-party content licenses</li>
                <li>Maintain the confidentiality of any login credentials we provide</li>
              </List>
            </SubSection>

            <SectionTitle>3. Intellectual Property Rights</SectionTitle>
            <SubSection>
              <Paragraph>
                Upon full payment of all fees:
              </Paragraph>
              <List>
                <li>You retain ownership of your content and materials provided to us</li>
                <li>You receive a license to use the final deliverables</li>
                <li>We retain ownership of pre-existing materials and generic elements</li>
                <li>We reserve the right to showcase the work in our portfolio</li>
              </List>
            </SubSection>

            <SectionTitle>4. Payment Terms</SectionTitle>
            <SubSection>
              <Paragraph>
                Our payment terms include:
              </Paragraph>
              <List>
                <li>50% deposit required to commence work</li>
                <li>Remaining balance due upon project completion</li>
                <li>Additional services billed at our standard hourly rate</li>
                <li>Late payments subject to a 2% monthly interest charge</li>
              </List>
            </SubSection>

            <SectionTitle>5. Project Timeline and Delivery</SectionTitle>
            <SubSection>
              <Paragraph>
                We commit to delivering projects according to agreed timelines, subject to:
              </Paragraph>
              <List>
                <li>Timely client feedback and content delivery</li>
                <li>Prompt payment of invoices</li>
                <li>No significant changes to project scope</li>
                <li>Reasonable revisions within agreed scope</li>
              </List>
            </SubSection>

            <SectionTitle>6. Limitation of Liability</SectionTitle>
            <SubSection>
              <Paragraph>
                NeonPixel shall not be liable for:
              </Paragraph>
              <List>
                <li>Any indirect, incidental, or consequential damages</li>
                <li>Loss of profits or business opportunities</li>
                <li>Issues arising from client-provided content</li>
                <li>Third-party service disruptions</li>
              </List>
            </SubSection>

            <SectionTitle>7. Termination</SectionTitle>
            <SubSection>
              <Paragraph>
                Either party may terminate the service agreement:
              </Paragraph>
              <List>
                <li>With 30 days written notice</li>
                <li>Immediately for material breach of these terms</li>
                <li>Upon mutual agreement</li>
              </List>
              <Paragraph>
                Upon termination, you agree to pay for all services rendered up to the termination date.
              </Paragraph>
            </SubSection>

            <SectionTitle>8. Governing Law</SectionTitle>
            <SubSection>
              <Paragraph>
                These terms are governed by and construed in accordance with the laws of the United Kingdom. 
                Any disputes shall be subject to the exclusive jurisdiction of the courts of England and Wales.
              </Paragraph>
            </SubSection>

            <SectionTitle>Contact Information</SectionTitle>
            <SubSection>
              <Paragraph>
                For any questions regarding these Terms of Service, please contact us at:
              </Paragraph>
              <Paragraph>
                Email: hello@neonpixeldesigns.com
              </Paragraph>
            </SubSection>

            <LastUpdated>
              Last updated: January 2024
            </LastUpdated>
          </Section>
        </ContentSection>
      </PageContainer>
    </>
  );
};

export default TermsOfServicePage;
