import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/SEO/SEO';
import { FaRocket, FaCheck, FaClock, FaComments, FaCogs, FaShieldAlt, FaChevronDown } from 'react-icons/fa';
import LandingNavbar from '../components/Navbar/LandingNavbar';
import LandingFooter from '../components/Footer/LandingFooter';

const Section = styled.section`
  width: 100%;
  padding: 5rem 0;
  position: relative;
`;

const MaxWidthContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const HeroSection = styled(Section)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, rgba(15, 0, 36, 0.98) 0%, rgba(25, 0, 45, 0.98) 100%);
  overflow: hidden;
  padding: 4rem 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(138, 43, 226, 0.15) 0%, transparent 70%);
  }
`;

const HeroContent = styled.div`
  max-width: 600px;
  position: relative;
  z-index: 2;
  
  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #8A2BE2 0%, #9945FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.1;
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  
  p {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
`;

const CTAButton = styled(motion.button)`
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  color: white;
  padding: 0.875rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
`;

const SectionSubtitle = styled.p`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 600px;
  margin: 0 auto 3rem;
  line-height: 1.6;
`;

const BenefitsSection = styled(Section)`
  background: rgba(15, 0, 36, 0.98);
  padding: 6rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 30% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const BenefitCard = styled.div`
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-4px);
    background: rgba(138, 43, 226, 0.08);
    border-color: rgba(138, 43, 226, 0.2);
  }

  svg {
    color: #8A2BE2;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
  }
`;

const TestimonialsSection = styled(Section)`
  background: rgba(20, 0, 36, 0.98);
  padding: 6rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 70% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const TestimonialCard = styled.div`
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;
  margin: 0 1rem;

  .stars {
    color: #FFD700;
    margin-bottom: 1rem;
    display: flex;
    gap: 0.25rem;
  }

  .quote {
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  .author {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
  }

  .position {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
`;

const ProcessSection = styled(Section)`
  background: rgba(20, 0, 36, 0.98);
  padding: 6rem 0;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProcessCard = styled.div`
  text-align: center;
  padding: 2rem;

  svg {
    color: #8A2BE2;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
  }
`;

const FAQSection = styled(Section)`
  background: rgba(15, 0, 36, 0.98);
  padding: 6rem 0;
`;

const FAQList = styled.div`
  max-width: 800px;
  margin: 3rem auto 0;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

const FAQButton = styled.button`
  width: 100%;
  text-align: left;
  padding: 1.25rem;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(138, 43, 226, 0.08);
  }

  svg {
    color: #8A2BE2;
    font-size: 1.25rem;
    transition: transform 0.3s ease;
    transform: ${props => props['aria-expanded'] ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

const FAQAnswer = styled(motion.div)`
  padding: 0 1.25rem 1.25rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
`;

const FormSection = styled(Section)`
  background: rgba(20, 0, 36, 0.98);
  padding: 6rem 0;
`;

const ConsultationForm = styled.form`
  max-width: 600px;
  margin: 3rem auto 0;
  background: rgba(138, 43, 226, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.1);
  border-radius: 12px;
  padding: 2rem;
  backdrop-filter: blur(10px);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
    background: rgba(255, 255, 255, 0.08);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 1rem;
  min-height: 120px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
    background: rgba(255, 255, 255, 0.08);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(138, 43, 226, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #8A2BE2;
    background: rgba(255, 255, 255, 0.08);
  }

  option {
    background: #1a0036;
    color: #fff;
  }
`;

const PrivacyNote = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 2rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    color: #8A2BE2;
  }
`;

const Footer = styled.footer`
  background: linear-gradient(135deg, rgba(15, 0, 36, 0.98) 0%, rgba(25, 0, 45, 0.98) 100%);
  padding: 4rem 0 2rem;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 30% 50%, rgba(138, 43, 226, 0.08) 0%, transparent 70%);
  }

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #8A2BE2;
    }
  }
`;

const DesignConsultation12 = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    business: '',
    budget: '',
    serviceType: '',
  });

  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const faqs = [
    {
      id: 1,
      question: "What happens during the consultation?",
      answer: "During the 30-minute consultation, we'll discuss your business goals, target audience, and website requirements. Our experts will provide initial recommendations and outline potential solutions tailored to your needs."
    },
    {
      id: 2,
      question: "Is this really free?",
      answer: "Yes, the consultation is completely free with no obligation. We believe in providing value upfront and helping you make an informed decision about your website project."
    },
    {
      id: 3,
      question: "How soon will I get my website?",
      answer: "Timeline varies depending on project scope, but typical projects take 4-8 weeks from start to finish. During the consultation, we'll provide a more accurate timeline based on your specific requirements."
    },
    {
      id: 4,
      question: "What's included in the website package?",
      answer: "Our website packages include custom design, responsive development, SEO optimization, content integration, and thorough testing. We'll discuss specific features and requirements during the consultation."
    }
  ];

  const testimonials = [
    {
      id: 1,
      name: "Sarah Johnson",
      company: "Bloom Digital Marketing",
      content: "Working with NeonPixel was a game-changer for our agency. They created a stunning website that perfectly captures our brand and has significantly increased our conversion rates.",
      rating: 5
    },
    {
      id: 2,
      name: "Michael Chen",
      company: "TechStart Solutions",
      content: "The team at NeonPixel delivered beyond our expectations. Their attention to detail and innovative approach to web design has helped us stand out in a competitive market.",
      rating: 5
    },
    {
      id: 3,
      name: "Emma Thompson",
      company: "Artisan Cafe Co.",
      content: "Our new website has completely transformed our online presence. We've seen a 200% increase in online bookings since launching. Couldn't be happier with the results!",
      rating: 5
    }
  ];

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleFAQ = (id: number) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  return (
    <>
      <SEO 
        title="Design Consultation - Transform Your Online Presence"
        description="Book your free consultation with our expert design team and discover how we can help you create a high-converting website."
        keywords="web design consultation, free consultation, website design, professional web design, custom website, web development"
        url="/design-consultation-12"
        noindex={true}
      />
      <LandingNavbar />
      
      <HeroSection>
        <MaxWidthContainer>
          <HeroContent>
            <h1>Get a Stunning Website That Converts</h1>
            <p>
              Book your free 30-minute strategy session with our expert design team and discover how to turn your website into a powerful business asset.
            </p>
            <CTAButton
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
            >
              Book Your Free Strategy Session →
            </CTAButton>
          </HeroContent>
        </MaxWidthContainer>
      </HeroSection>

      <BenefitsSection>
        <MaxWidthContainer>
          <SectionTitle>Why Choose Our Web Design Service?</SectionTitle>
          <BenefitsGrid>
            <BenefitCard>
              <FaRocket />
              <h3>Boost Your Online Presence</h3>
              <p>Stand out from competitors with a stunning, modern website that captures attention and drives engagement.</p>
            </BenefitCard>
            <BenefitCard>
              <FaCheck />
              <h3>Conversion-Focused Design</h3>
              <p>Turn visitors into customers with strategic layouts and compelling calls-to-action that drive results.</p>
            </BenefitCard>
            <BenefitCard>
              <FaClock />
              <h3>Quick Turnaround</h3>
              <p>Get your website up and running in weeks, not months, with our efficient design and development process.</p>
            </BenefitCard>
          </BenefitsGrid>
        </MaxWidthContainer>
      </BenefitsSection>

      <TestimonialsSection>
        <MaxWidthContainer>
          <SectionTitle>What Our Clients Say</SectionTitle>
          <SectionSubtitle>Join hundreds of satisfied clients who've transformed their online presence</SectionSubtitle>
          <TestimonialGrid>
            <TestimonialCard>
              <div className="stars">★★★★★</div>
              <p className="quote">Working with NeonPixel was a game-changer for our agency. They created a stunning website that perfectly captures our brand and has significantly increased our conversion rates.</p>
              <p className="author">Sarah Johnson</p>
              <p className="position">Brand Digital Marketing</p>
            </TestimonialCard>
            <TestimonialCard>
              <div className="stars">★★★★★</div>
              <p className="quote">The team at NeonPixel delivered exceptional results. Their approach to web design has helped us stand out in a competitive market.</p>
              <p className="author">Michael Chen</p>
              <p className="position">TechWave Solutions</p>
            </TestimonialCard>
            <TestimonialCard>
              <div className="stars">★★★★★</div>
              <p className="quote">Our new website has completely transformed our online presence. We've seen a 300% increase in online bookings since launching. Couldn't be happier with the results!</p>
              <p className="author">Emma Thompson</p>
              <p className="position">Fitness Hub Co.</p>
            </TestimonialCard>
          </TestimonialGrid>
        </MaxWidthContainer>
      </TestimonialsSection>

      <ProcessSection>
        <MaxWidthContainer>
          <SectionTitle>Here's How It Works</SectionTitle>
          <ProcessGrid>
            <ProcessCard>
              <FaComments />
              <h3>1. Book Your Free Consultation</h3>
              <p>Fill out the form below to get started</p>
            </ProcessCard>
            <ProcessCard>
              <FaCogs />
              <h3>2. Discuss Your Vision</h3>
              <p>Share your needs to outline your goals</p>
            </ProcessCard>
            <ProcessCard>
              <FaShieldAlt />
              <h3>3. Get Your Custom Plan</h3>
              <p>Receive a tailored website strategy & proposal</p>
            </ProcessCard>
          </ProcessGrid>
        </MaxWidthContainer>
      </ProcessSection>

      <FAQSection>
        <MaxWidthContainer>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <FAQList>
            {faqs.map((faq) => (
              <FAQItem key={faq.id}>
                <FAQButton
                  onClick={() => toggleFAQ(faq.id)}
                  aria-expanded={openFAQ === faq.id}
                >
                  {faq.question}
                  <FaChevronDown />
                </FAQButton>
                {openFAQ === faq.id && (
                  <FAQAnswer
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {faq.answer}
                  </FAQAnswer>
                )}
              </FAQItem>
            ))}
          </FAQList>
        </MaxWidthContainer>
      </FAQSection>

      <FormSection ref={formRef}>
        <MaxWidthContainer>
          <SectionTitle>Start Your Journey Today</SectionTitle>
          <ConsultationForm onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Phone Number</Label>
              <Input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Tell us about your business</Label>
              <TextArea
                name="business"
                value={formData.business}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <Label>Budget Range</Label>
              <Select
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                required
              >
                <option value="">Select a range</option>
                <option value="2000-5000">£2,000 - £5,000</option>
                <option value="5000-10000">£5,000 - £10,000</option>
                <option value="10000+">£10,000+</option>
              </Select>
            </FormGroup>
            
            <FormGroup>
              <Label>Service Type</Label>
              <Select
                name="serviceType"
                value={formData.serviceType}
                onChange={handleChange}
                required
              >
                <option value="">Select a service</option>
                <option value="web-design">Web Design</option>
                <option value="web-app">Web Application</option>
              </Select>
            </FormGroup>
            
            <CTAButton
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Schedule Your Free Consultation
            </CTAButton>
          </ConsultationForm>
          
          <PrivacyNote>
            <FaShieldAlt /> We value your privacy. Your information is secure and will never be shared.
          </PrivacyNote>
        </MaxWidthContainer>
      </FormSection>
      <Footer>
        <LandingFooter />
      </Footer>
    </>
  );
};

export default DesignConsultation12;
