import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { Services } from '../components/Services/Services';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const StyledServices = styled(Services)`
  padding-top: 2rem;
  
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
`;

const ServicesPage: React.FC = () => {
  const pageTitle = "Professional Web Design & Development Services | NeonPixel Designs";
  const pageDescription = "Transform your online presence with our expert web design, development, and digital marketing services. Specialising in modern, responsive websites and e-commerce solutions.";
  const keywords = "web design services, web development, responsive design, e-commerce solutions, SEO services, digital marketing, UK web design";

  return (
    <PageContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://neonpixeldesigns.com/services" />
        <link rel="canonical" href="https://neonpixeldesigns.com/services" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${pageTitle}",
              "description": "${pageDescription}",
              "provider": {
                "@type": "Organisation",
                "name": "Neon Pixel Designs",
                "url": "https://neonpixeldesigns.com"
              },
              "serviceType": ["Web Design", "Web Development", "E-commerce Solutions", "Digital Marketing"],
              "areaServed": "United Kingdom"
            }
          `}
        </script>
      </Helmet>
      <SEO
        title="Digital Services & Solutions | NeonPixel Designs"
        description="Discover our full range of digital services including web design, development, UI/UX, and digital marketing. Comprehensive solutions to establish your brand's digital presence."
        keywords="digital agency services, web solutions, creative digital services, professional web services, digital transformation, web agency london, tech consulting"
        url="/services"
        type="services"
      />
      <PageHeader
        title="Our Services"
        subtitle="We offer comprehensive digital solutions tailored to your needs. From stunning web design to cutting-edge development, we bring your vision to life with precision and creativity."
      />
      <StyledServices showFeatures={true} />
    </PageContainer>
  );
};

export default ServicesPage;
