import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO/SEO';
import { FaPalette, FaMobile, FaCode, FaRocket, FaCogs, FaTools } from 'react-icons/fa';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
`;

const Container = styled.div`
  max-width: ${props => props.theme.breakpoints.wide};
  margin: 0 auto;
  padding: 4rem 2rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 1rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 4rem;
`;

const ServiceCard = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(40, 40, 40, 0.6);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`;

const ProcessSection = styled.div`
  margin-top: 4rem;
`;

const ProcessGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ProcessStep = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: start;
`;

const StepNumber = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  font-weight: bold;
`;

const StepContent = styled.div``;

const StepTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const TechStackSection = styled.div`
  margin-top: 4rem;
`;

const TechGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
`;

const TechCard = styled.div`
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(40, 40, 40, 0.6);
  }
`;

const TechIcon = styled.div`
  font-size: 2rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const TechTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const TechDescription = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 0.9rem;
  line-height: 1.6;
`;

const WebDesign = () => {
  const pageTitle = "Professional Web Design Services | NeonPixel Designs";
  const pageDescription = "Transform your online presence with our expert web design services. We create modern, responsive, and user-friendly websites that drive results.";

  const services = [
    {
      title: "Custom Design",
      description: "Unique, tailored designs that reflect your brand identity and meet your specific business needs."
    },
    {
      title: "Responsive Layout",
      description: "Websites that look and function perfectly on all devices, from mobile phones to desktop computers."
    },
    {
      title: "User Experience",
      description: "Intuitive navigation and interaction design that keeps your users engaged and converts visitors into customers."
    },
    {
      title: "Modern Technologies",
      description: "Built with the latest web technologies to ensure fast loading times and smooth performance."
    }
  ];

  const processSteps = [
    {
      number: "1",
      title: "Discovery & Planning",
      description: "We begin by understanding your business goals, target audience, and project requirements through detailed consultation."
    },
    {
      number: "2",
      title: "Design & Wireframing",
      description: "Creating detailed wireframes and design mockups that align with your brand and user experience goals."
    },
    {
      number: "3",
      title: "Development & Testing",
      description: "Building your website with clean, efficient code and thorough testing across all devices and browsers."
    },
    {
      number: "4",
      title: "Launch & Support",
      description: "Deploying your website and providing ongoing support and maintenance to ensure continued success."
    }
  ];

  const techStack = [
    {
      icon: <FaPalette />,
      title: "Responsive UI/UX Design",
      description: "Mobile-first approach"
    },
    {
      icon: <FaMobile />,
      title: "Interactive Prototypes",
      description: "Dynamic mockups"
    },
    {
      icon: <FaCode />,
      title: "Styled-Components",
      description: "Modern CSS-in-JS"
    },
    {
      icon: <FaRocket />,
      title: "Framer Motion",
      description: "Smooth animations"
    },
    {
      icon: <FaCogs />,
      title: "Material-UI / Tailwind",
      description: "UI frameworks"
    },
    {
      icon: <FaTools />,
      title: "Modern Design Systems",
      description: "Consistent aesthetics"
    }
  ];

  return (
    <>
      <SEO
        title="Custom Web Design Services | UI/UX Design Experts"
        description="Expert web design services focused on creating visually stunning, user-friendly websites. We specialize in responsive design, brand identity, and conversion optimization."
        keywords="custom website design, UI design services, UX optimization, responsive web design, brand identity design, web design agency london, mobile-first design"
        url="/services/web-design"
        type="service"
      />
      <PageContainer>
        <PageHeader 
          title="Web Design Services"
          subtitle="Creating beautiful, functional websites that drive results"
        />

        <Container>
          <ServicesGrid>
            {services.map((service, index) => (
              <ServiceCard key={index}>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceCard>
            ))}
          </ServicesGrid>

          <ProcessSection>
            <SectionTitle>Our Design Process</SectionTitle>
            <ProcessGrid>
              {processSteps.map((step, index) => (
                <ProcessStep key={index}>
                  <StepNumber>{step.number}</StepNumber>
                  <StepContent>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </StepContent>
                </ProcessStep>
              ))}
            </ProcessGrid>
          </ProcessSection>

          <TechStackSection>
            <SectionTitle>Our Tech Stack</SectionTitle>
            <TechGrid>
              {techStack.map((tech, index) => (
                <TechCard key={index}>
                  <TechIcon>{tech.icon}</TechIcon>
                  <TechTitle>{tech.title}</TechTitle>
                  <TechDescription>{tech.description}</TechDescription>
                </TechCard>
              ))}
            </TechGrid>
          </TechStackSection>
        </Container>
      </PageContainer>
    </>
  );
};

export default WebDesign;
